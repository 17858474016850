<template lang="pug">
  #rental
    .contents-wrap
      Title
        template(v-slot:img_src)
          IcoRental
        template(v-slot:ttl_name)
          NameRental
        template(v-slot:ttl_kana)
          | {{ ttl_kana }}
        template(v-slot:ttl_option)
          span.cap お泊まりでないお客様もご利用になれます

      .half-wrap
        .half
          .img-wrap(v-lazy-container="{ selector: 'img' }")
            img(:data-src="img_dendo" alt="電動アシスト自転車のレンタル")

        .half.flex-center-column
          .strong-wrap
            span.strong
              | 電動アシスト自転車（5台）
          .texts-wrap
            p
              span 1日：￥3,000（1台）
              span 伊豆大島の風を感じてサイクリングするなら、電動アシスト自転車がオススメです。

      .half-wrap
        .half
          .img-wrap(v-lazy-container="{ selector: 'img' }")
            img(:data-src="img_kougu" alt="無料でレンタルできるもの")

        .half.flex-center-column
          .strong-wrap
            h3.strong
              | ［ 無料レンタル ］

          .texts-wrap
            ul
              li 空気入れ
              li 自転車用工具（原則宿内利用のみ）

          .strong-wrap
            h3.strong
              | ［ その他有料サービス ］
              span.caption （各¥500〜/日）
          .texts-wrap
            ul
              li シュノーケリングセット
              li 釣り竿
              li 釣り用氷
              li クーラーボックス
              li BBQ用 炭・着火剤
              li タオルなど
            span.caption
              | （伊豆大島のルールで「魚突き」は禁止です）

</template>

<script>
  import Title from '@/components/parts/Title'
  import IcoRental from '@/components/title/IcoRental'
  import NameRental from '@/components/title/NameRental'

  export default {
    components: {
      Title,
      IcoRental,
      NameRental
    },
    data () {
      return {
        img_dendo: require('@/assets/img_dendo.png'),
        img_mama: require('@/assets/img_mama.png'),
        img_kougu: require('@/assets/img_kougu.png'),
        ttl_kana: '［レンタル］'
      }
    }
  }
</script>
