<template lang="pug">
  div
    .warn-wrap.color-key
      .warnico-wrap
        img(src="@/assets/ico_warn.svg")
      slot
</template>

<script>
  export default {
    data () {
      return {
        //
      }
    }
  }
</script>

<style lang="scss">
  // color
  $bgcolor: #fff6dc;
  $keycolor: #EF5A00;
  $basecolor: #000000;
  $skycolor: #4BB5C7;

  .warn-wrap {
    position: relative;
    margin-bottom: 20px;
    padding: 20px 15px;
    border: 1px $keycolor solid;
    .warnico-wrap {
      position: absolute;
      top: -15px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 50px;
      background: $bgcolor;
      text-align: center;
    }
    p {
      &:last-child {
        margin-bottom: 0;
      }
      span {
        display: block;
      }
    }
  }

</style>
