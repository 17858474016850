<template lang="pug">
  #stay
    .contents-wrap
      Title
        template(v-slot:img_src)
          IcoStay
        template(v-slot:ttl_name)
          NameStay
        template(v-slot:ttl_kana)
          | {{ ttl_kana }}

      .half-wrap.rooms
        .half
          swiper
            swiper-slide
              img(:src="img_ka1")
            swiper-slide
              img(:src="img_ka2")
            swiper-slide
              img(:src="img_ka3")
            .swiper-pagination(slot="pagination")
            .swiper-button-next(slot="button-next")
              v-icon(color="white") chevron_right
            .swiper-button-prev(slot="button-prev")
              v-icon(color="white") chevron_left

          .strong-wrap
            span.strong
              | 花
          .texts-wrap
            ul
              li 定員3〜5名
              li エアコン
              li テーブル
              li 鍵付き
              li コンセント
              li 2段ベッド

        .half
          swiper
            swiper-slide
              img(:src="img_cho1")
            swiper-slide
              img(:src="img_cho2")
            swiper-slide
              img(:src="img_cho3")
            .swiper-pagination(slot="pagination")
            .swiper-button-next(slot="button-next")
              v-icon(color="white") chevron_right
            .swiper-button-prev(slot="button-prev")
              v-icon(color="white") chevron_left
    
          .strong-wrap
            span.strong
              | 鳥
          .texts-wrap
            ul
              li 定員3〜5名
              li エアコン
              li テーブル
              li 鍵付き
              li コンセント
              li ベッド

      .half-wrap

        .half
          swiper
            swiper-slide
              img(:src="img_fu1")
            swiper-slide
              img(:src="img_fu2")
            swiper-slide
              img(:src="img_fu3")
            .swiper-pagination(slot="pagination")
            .swiper-button-next(slot="button-next")
              v-icon(color="white") chevron_right
            .swiper-button-prev(slot="button-prev")
              v-icon(color="white") chevron_left
    
          .strong-wrap
            span.strong
              | 風
          .texts-wrap
            ul
              li 定員4〜5名
              li エアコン
              li テーブル
              li 鍵付き
              li コンセント
              li 2段ベッド

        .half
          swiper
            swiper-slide
              img(:src="img_getsu1")
            swiper-slide
              img(:src="img_getsu2")
            swiper-slide
              img(:src="img_getsu3")
            .swiper-pagination(slot="pagination")
            .swiper-button-next(slot="button-next")
              v-icon(color="white") chevron_right
            .swiper-button-prev(slot="button-prev")
              v-icon(color="white") chevron_left
    
          .strong-wrap
            span.strong
              | 月
          .texts-wrap
            ul
              li 定員2〜4名
              li エアコン
              li テーブル
              li 鍵付き
              li コンセント
              li 2段ベッド

      .half-wrap
        .half
          swiper
            swiper-slide
              img(:src="img_lobby1")
            swiper-slide
              img(:src="img_lobby2")
            .swiper-pagination(slot="pagination")
            .swiper-button-next(slot="button-next")
              v-icon(color="white") chevron_right
            .swiper-button-prev(slot="button-prev")
              v-icon(color="white") chevron_left

        .half.flex-center-column
          .strong-wrap
            span.strong
              | ●1F共有ロビー
            span （持ち込みの自転車を屋内に保管できます）
            span.strong
              | ●シャワー（24h）etc.

    Foods

</template>

<script>
  import Foods from '@/components/Foods'
  import Title from '@/components/parts/Title'
  import IcoStay from '@/components/title/IcoStay'
  import NameStay from '@/components/title/NameStay'

  export default {
    components: {
      Foods,
      Title,
      IcoStay,
      NameStay
    },
    data () {
      return {
        img_ka1: require('@/assets/ka2.jpg'),
        img_ka2: require('@/assets/ka1.jpg'),
        img_ka3: require('@/assets/ka3.jpg'),
        img_cho1: require('@/assets/cho1.jpg'),
        img_cho2: require('@/assets/cho2.jpg'),
        img_cho3: require('@/assets/cho3.jpg'),
        img_fu1: require('@/assets/fu1.jpg'),
        img_fu2: require('@/assets/fu2.jpg'),
        img_fu3: require('@/assets/fu3.jpg'),
        img_getsu1: require('@/assets/getsu3.jpg'),
        img_getsu2: require('@/assets/getsu2.jpg'),
        img_getsu3: require('@/assets/getsu1.jpg'),
        img_lobby1: require('@/assets/lobby1.jpg'),
        img_lobby2: require('@/assets/lobby2.jpg'),
        ttl_kana: '［宿泊］'
      }
    }
  }
</script>

<style lang="scss">
</style>
