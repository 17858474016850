import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import VueScrollTo from 'vue-scrollto'
import VueLazyload from 'vue-lazyload'
import './stylus/main.styl'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

Vue.config.productionTip = false

Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: -20,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: '/error.svg',
  loading: '/loading.svg',
  attempt: 1
})

var loading = document.getElementById('loading')
var contents = document.getElementById('app')

window.addEventListener('load', function(){
	loading.style.display = 'none'
	contents.classList.remove('d-none')
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}, false)

Vue.use(VueAwesomeSwiper, {
  pagination: {
    el: '.swiper-pagination',
    clickable: true
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

