<template lang="pug">
  div
    img(src="@/assets/menu_stay.svg" alt="ゲストハウスのお部屋のご案内")
</template>

<script>
  export default {
    components: {
      //
    },
    data () {
      return {
        //
      }
    }
  }
</script>

<style lang="scss">
</style>
