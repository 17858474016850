<template lang="pug">
  #about
    .contents-wrap
      Title
        template(v-slot:img_src)
          IcoAbout
        template(v-slot:ttl_name)
          NameAbout
        template(v-slot:ttl_kana)
          | {{ ttl_kana }}

      .subttl-wrap
        h3
          | 伊豆大島を満喫するなら、
          br.sp
          | 手ぶらで遊べるゲストハウスへ

      .texts-wrap
        p
          | 大島は
          b アクティビティの宝庫
          | ！ 山を散策する、海で泳ぐ、釣りをする、電動アシスト自転車で島内を巡る、温泉で疲れを癒し、軒先で気楽にBBQをする…。
        p
          | 旅を楽しくするレンタルを行う『Volcanic Island』（ボルカニックアイランド）
          | の宿泊施設なら、
          b 手ぶらで伊豆大島を満喫できます。
        p
          | 伊豆大島に詳しいスタッフが常駐しているので、
          b ノープランでも大丈夫。
          | あなたのお話を伺って、ピッタリの観光スポットや大自然と触れ合う様々なアイデアをご紹介します。
        p のんびり癒されるも良し、思いっきり遊ぶも良し。
        p 東京から約1時間45分、懐かしい非日常のゲストハウスへぜひお気軽にお越しください。
      swiper
        swiper-slide
          img(:src="img_gaikan1" alt="外観" title="宿の外観")
        swiper-slide
          img(:src="img_gaikan2" alt="外観" title="宿の外観")
        swiper-slide
          img(:src="img_gaikan3" alt="外観" title="宿の外観")
        .swiper-pagination(slot="pagination")
        .swiper-button-next(slot="button-next")
          v-icon(color="white") chevron_right
        .swiper-button-prev(slot="button-prev")
          v-icon(color="white") chevron_left


</template>

<script>
  import Title from '@/components/parts/Title'
  import IcoAbout from '@/components/title/IcoAbout'
  import NameAbout from '@/components/title/NameAbout'

  export default {
    components: {
      Title,
      IcoAbout,
      NameAbout
    },
    data () {
      return {
        img_src: require('@/assets/gaikan1.jpg'),
        img_gaikan1: require('@/assets/gaikan3.jpg'),
        img_gaikan2: require('@/assets/gaikan2.jpg'),
        img_gaikan3: require('@/assets/gaikan1.jpg'),
        ttl_kana: '［ボルカニックアイランドについて］'
      }
    }
  }
</script>
