<template lang="pug">
  #foods
    .contents-wrap

      .subttl-wrap
        h3.color-key
          | [ お食事について ]
      .half-wrap
        .half
          swiper
            swiper-slide
              img(:src="img_food")
            swiper-slide
              img(:src="img_kitchen")
            .swiper-pagination(slot="pagination")
            .swiper-button-next(slot="button-next")
              v-icon(color="white") chevron_right
            .swiper-button-prev(slot="button-prev")
              v-icon(color="white") chevron_left

        .texts-wrap.half.flex-center-column
          p
            span
              | Volcanic Island は素泊まり宿です。
            span
              | お食事の提供はしておりませんが、BBQ、または共有キッチンを自由に使って、お客様ご自身でお料理を楽しんでいただけます（
              strong BBQの道具、キッチン、お皿などの使用料は無料、炭は有料
              | ）。もちろん食材、飲料の持ち込みOK！
            span
              | 近隣には食料品の商店やスーパーも豊富にございますので、好みの食材を探していただくことも可能です。
            span
              | 外食をご希望の方にはオススメの飲食店をご紹介いたします。
          p
            strong.color-key [ キッチン ]
            ul
              li ガスコンロ
              li 冷蔵庫
              li 冷凍庫
              br.sp
              li レンジ
              li 炊飯器
              li 食器類
          p
            strong.color-key [ BBQ ]
            ul
              li コンロ
              li テーブル、椅子

      Caution
        p 大島にはコンビニがありません。スーパーは数店舗あり、最寄りのスーパー「ベニヤ」さんは19時までの営業となります。
        p Volcanic Islandのある元町地区は、大島の中ではランチや夜ご飯を食べられるお店が多いほうですが、都会と比べると数は限られてしまいます。島でのお食事についてはぜひスタッフにご相談ください。
      .button-wrap
        span.cap-sky \ 島ごはんや周辺環境についてはこちら /
        a.link-sky(href="https://oshima-navi.com/" target="_blank")
          span 楽しみ方発見サイト 大島ナビ
          v-icon launch
</template>

<script>
  import Caution from '@/components/parts/Caution'

  export default {
    components: {
      Caution
    },
    data () {
      return {
        img_kitchen: require('@/assets/img_kitchen.png'),
        img_food: require('@/assets/img_sashimi.png'),
      }
    }
  }
</script>
