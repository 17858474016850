<template lang="pug">
  div
    .ttl-wrap
      slot(name="img_src")
      h2
        slot(name="ttl_name")
      hr
      span.kana
        slot(name="ttl_kana")
      slot(name="ttl_option")

</template>

<script>
  export default {
    data () {
      return {
        //
      }
    }
  }
</script>