<template lang="pug">
  #access
    .contents-wrap
      Title
        template(v-slot:img_src)
          IcoAccess
        template(v-slot:ttl_name)
          NameAccess
        template(v-slot:ttl_kana)
          | {{ ttl_kana }}

      .map-wrap
        iframe(src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3278.2523823145893!2d139.35289171526662!3d34.749234688124965!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60177985752d94a1%3A0x3181af1114328c3a!2sVolcanic+Island!5e0!3m2!1sja!2sjp!4v1563860621026!5m2!1sja!2sjp" width="100%" height="350" frameborder="0" style="border: 0" allowfullscreen)
      .texts-wrap
        p
          a.link-wire(href="https://goo.gl/maps/P3UceLQ2WnLnAJeM6" target="_blank")
            span 地図アプリで見る
        p
          span Volcanic Island
          span 〒100-0101  東京都大島町元町2丁目2番2号
        p.strong-wrap
          span.strong ●元町港から徒歩 約5分
          span.strong ●岡田港から車で約14分

        p
          span
            strong ●大島空港から車で約8分
          span 大型客船の岡田港早朝到着の場合は「元町港 経由 御神火温泉行」の接続バスがございます。

      Caution
        p
          | 伊豆大島には、北部の
          strong 岡田港（おかた）港
          | 、西部の
          strong 元町（もとまち）港
          | という2つの港があります。
        p
          strong どちらに到着するかは、海況によって決まるため、当日まで知ることができません。
          | 到着先のご確認は、渡航当日に下記リンクにてご確認ください。
      
      .button-wrap
        span.cap \ 本日の到着先をチェック! /
        a.link-other(href="https://www.tokaikisen.co.jp/schedule/" target="_blank")
          span 東海汽船運行状況
          v-icon launch

    WayToOhshima

</template>

<script>
  import Title from '@/components/parts/Title'
  import IcoAccess from '@/components/title/IcoAccess'
  import NameAccess from '@/components/title/NameAccess'
  import WayToOhshima from '@/components/WayToOhshima'
  import Caution from '@/components/parts/Caution'

  export default {
    components: {
      Title,
      IcoAccess,
      NameAccess,
      WayToOhshima,
      Caution
    },
    data () {
      return {
        ttl_kana: '［アクセス］'
      }
    }
  }
</script>

<style lang="scss">
</style>
