<template lang="pug">
  #contact
    .contents-wrap
      Title
        template(v-slot:img_src)
          IcoContact
        template(v-slot:ttl_name)
          NameContact
        template(v-slot:ttl_kana)
          | {{ ttl_kana }}

      .texts-wrap.mb-narrow.text-center
        strong.textsize-strong ご質問、ご予約 
        | は 
        strong.textsize-strong お電話、メール、
        | またはチケット販売サイトにてお申し込みください。

      .button-wrap
        a.link-key(href="mailto:info@volcanic-island.tokyo")
          img(src="@/assets/ico_mail.svg")
          span.weak info@volcanic-island.tokyo

      .button-wrap
        a.link-key(href="tel:080-8118-7412" target="_blank")
          img(src="@/assets/ico_tel.svg")
          span TEL：080-8118-7412

      .button-wrap
        a.link-key-narrow(href="https://www.booking.com/hotel/jp/volcanic-island-da-dao.ja.html?aid=339530&utm_source=pmk&utm_medium=email&utm_campaign=hoteladminmg_welcome_new_ob" target="_blank")
          span
            | WEB予約
            v-icon launch

</template>

<script>
  import Title from '@/components/parts/Title'
  import IcoContact from '@/components/title/IcoContact'
  import NameContact from '@/components/title/NameContact'

  export default {
    components: {
      Title,
      IcoContact,
      NameContact
    },
    data () {
      return {
        ttl_kana: '［チケット&連絡先］'
      }
    }
  }
</script>

<style lang="scss" scoped>
  // color
  $bgcolor: #fff6dc;
  $keycolor: #EF5A00;
  $basecolor: #000000;
  $skycolor: #4BB5C7;
  
  .text-center {
    text-align: center;
  }
</style>
