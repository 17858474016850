<template lang="pug">
  v-layout(wrap)
    .hero-wrap
      .herobg.bg1
      .herobg.bg2
      .herobg.bg3
      .herobg.bg4
      .herobg.bg5
      .hero-contents
        .img-wrap
          img(src="@/assets/logo.png" alt="レンタルサービスがある伊豆大島のゲストハウス「Volcanic Island」")
        span 山と海と自転車と
        .buttons-wrap
          a(v-scroll-to="'#rental'")
            button
              .color-key R
              | ENTAL
          a(v-scroll-to="'#stay'")
            button
              | S
              .color-key T
              | AY
</template>

<script>
  export default {
    data () {
      return {
        //
      }
    },
    methods: {
      //
    }
  }
</script>
