<template lang="pug">
  v-app

    // header
    .header-wrap
      .topbar-wrap
        a(href="/")
          h1
            span.font-weight-bold Volcanic Island
            span.font-weight-light izuoshima
      Nav

    // back to top
    .to-top-wrap#testkey
      a.ticket(href="#top")
        img(src="@/assets/ico_back.png" alt="トップに戻る")
        span TOP

    // content
    v-content#top
      HeroHeader

      v-container
        v-layout(wrap)
          v-flex(xs12 sm8 lg10 xl6 offset-sm2 offset-lg1 offset-xl3 mb-4)
            About
            Rental
            Stay
            Amenity
            Access
            Fee
            Contact

      Footer

</template>

<script>
import HeroHeader from '@/components/HeroHeader'
import Nav from '@/components/Nav'
import About from '@/components/About'
import Rental from '@/components/Rental'
import Stay from '@/components/Stay'
import Amenity from '@/components/Amenity'
import Access from '@/components/Access'
import Fee from '@/components/Fee'
import Contact from '@/components/Contact'
import Footer from '@/components/Footer'

export default {
  name: 'App',
  components: {
    Nav,
    HeroHeader,
    About,
    Rental,
    Stay,
    Amenity,
    Access,
    Fee,
    Contact,
    Footer
  },
  data () {
    return {
      isMobileNavActive: false
    }
  },
  mounted () {
    var buttonTop = document.getElementById('testkey');
    //console.log('buttontop', buttonTop)
    function getScrolled() {
     return ( window.pageYOffset !== undefined ) ? window.pageYOffset: document.documentElement.scrollTop;
    }
    window.onscroll = function() {
      ( getScrolled() < 100 ) ? buttonTop.classList.add( 'd-none' ): buttonTop.classList.remove( 'd-none' );
    }
  }
}
</script>

<style lang="scss">
  @import '~@/stylus/global.scss';
</style>