<template lang="pug">
  #footer
    v-footer(
      height="auto"
      color="black"
    )
      v-layout(
        justify-center
        row
        wrap
      )
        v-flex(
          text-xs-center
          white--text
          xs7
        )
          .half-wrap
            a.link-wire(v-on:click="showPP=!showPP")
              span.caption プライバシーポリシー
            a.link-wire(v-on:click="showTerms=!showTerms")
              span.caption 宿泊約款
            a.link-wire(v-on:click="showRules=!showRules")
              span.caption ご利用規則

          .copyright
            span.caption Copyright &copy; 2019 Volcanic Island
            br.sp
            span.caption All rights reserved.

    .dialog-terms(v-if="showPP")
      .nav-wrap(v-on:click="showPP=false")
        a.menu
          img(src="@/assets/ico_close.svg")
      v-container
        v-layout(wrap)
          v-flex(xs12 sm8 lg6 xl6 offset-sm2 offset-lg3 offset-xl3 mb-4)
            .terms-name プライバシーポリシー
            .terms-contents
              p
                | Volcanic Island（以下「当宿」と呼びます）でご利用いただくお客様個人を特定することが出来る情報（以下「個人情報」と呼びます）を重要なものと認識し、プライバシーの保護に努めております。 本ページでは個人情報取扱いにおける基本方針を掲載しておりますのでご覧下さい。 ご記入いただきましたお客様の個人情報は、当宿における催事や新サービスのご紹介等、営業に関するご案内を郵送、電子メールにて送付するために利用させていただくことがございます。また、今後の業務運用、サービス改善等、マーケティング活動に貴重なご意見として活用させていただきます。なお、お客様の個人情報は法令および当宿内部規則に基づき厳重に管理いたします。
              p
                | ［ 1 ］
                br
                | 当宿ホームページでお客様からいただいた個人情報は、以下の場合に限り利用し、お客様が個人情報を提供された目的以外に利用することはありません。
              p
                ul
                  li
                    | 予約・施設利用管理のため
                  li
                    | 資料送付および商品送付・お問い合わせへのご回答などの各種手配のため
                  li
                    | イベントやご利用プラン等の情報のご案内を送付するため
                  li
                    | 当宿ホームページやサービス・商品改善のために分析をする場合、個人情報を除いた統計情報として利用いたします。
                  li
                    | 会員組織における会員サービス提供のため
                  li
                    | 当宿施設の利用者へのサービス提供のため
                  li
                    | その他、当宿がお客様へのご連絡が必要と判断した場合
              p
                | ［ 2 ］
                br
                | 当宿は、法令に定める場合等を除き、個人情報を、事前に本人の同意を得ることなく、第三者に提供しません。ただし、業務遂行上必要な範囲で、委託先へお客様の個人情報を提供する場合があります。この場合は委託先に対し秘密を保持させるために適正な監督をおこないます。
              p
                | ［ 3 ］
                br
                | お客様が第三者に不利益を及ぼすと当宿が判断した上で、当該第三者または警察等に開示する場合 ・人の生命、身体、財産等に対する差し迫った危険があり、それらの重大な利益を保護するために必要でやむを得ないと判断する場合 ・裁判所、検察庁、警察、弁護士会、消費者センターまたはこれらに準ずる権限を有する機関から、お客様の個人情報についての開示を求められた場合 ・本サービスの権利や財産を保護する目的で個人情報を開示する必要が生じた場合 ・情報の開示や共有についてお客様の同意がある場合 また、協力企業 ( 協力企業となりうる企業を含みます ) 、広告主、その他の第三者に当宿のサービスを説明する際、またその他の合法的な目的のために、お客様の統計情報を開示する場合があります。但し、統計情報には、個々のお客様を識別できる情報は含まれないものとします。
              p
                | ［ 4 ］
                br
                | 当宿が、個人情報の処理を外部へ委託する場合には、漏えいや再提供を行わないよう契約により義務づけ、適切な管理を実施させていただきます。
              p
                | ［ 5 ］
                br
                | ご提供いただいた個人情報につきましては、 ［1］ 2. の利用目的を達成するため、業務委託先又は提携先に預託する場合がございます。かかる場合には、情報漏えいや再提供を行わないよう契約により義務づけ、適切な管理を実施します。 お客様が、お客様ご自身の個人情報の確認、訂正等を希望される場合には、お客様ご自身により当宿までご連絡いただければ、合理的な範囲ですみやかに対応させていただきます。
              p
                | ［ 6 ］
                br
                | 本サービスでは、お客様の利便性を向上させるためクッキーを使用しています。お客様は、ブラウザの設定により、クッキーの受け取りを拒否する、もしくはクッキーを受け取ったとき警告を表示させることができます。
              p
                | ［ 7 ］
                br
                | 当宿が保有する個人情報に関して適用される法令、規範を遵守するとともに、上記各項における取り組み及び保護活動を、維持、改善してまいります。 
                br
                | ※個人情報保護方針に関するお問合せは、
                span.text-red info@volcanic-island.tokyo
                | までご連絡ください。

    .dialog-terms(v-if="showTerms")
      .nav-wrap(v-on:click="showTerms=false")
        a.menu
          img(src="@/assets/ico_close.svg")
      v-container
        v-layout(wrap)
          v-flex(xs12 sm8 lg6 xl6 offset-sm2 offset-lg3 offset-xl3 mb-4)
            .terms-name 宿泊約款
            .terms-contents
              p
                | (本約款の適用) 
                br
                | 第１条
                br
                | １ 当宿泊施設（以下、「当宿」といいます。）が宿泊者との間で締結する宿泊契約およびこれに関連する契約は、この約款に定めるところによるものとし、この約款に定められていない事項については、法令または一般に確立された慣習によるものとします。
                | ２ 当宿は、前項の規定にかかわらず、この約款の趣旨、法令および慣習に反しない範囲で特約に応じることができます。
              p
                | (宿泊契約締結の拒絶)
                br
                | 第２条
                br
                | 当宿は、次の場合には、宿泊契約の締結をお断りすることがあります。
                br
                | ⑴ 宿泊の申し込みが、この約款によらないものであるとき。
                br
                | ⑵ 満室(員)により客室の余裕がないとき。
                br
                | ⑶ 宿泊しようとする者が、宿泊に関し、法令の規定または公の秩序若しくは善良の風俗に反する行為をするおそれがあると認められるとき。
                br
                | ⑷ 宿泊しようとする者が、「暴力団員による不当な行為の防止等に関する法律」に定める指定暴力団および指定暴力団連合またはその構成員、関係者、その他の反社会的勢力(以下、「反社会的勢力」といいます。)であると認められるとき。
                br
                | ⑸ 宿泊しようとする者が、反社会的勢力が事業活動を支配する法人その他の団体であるとき。
                br
                | ⑹ 宿泊しようとする者が、法人その他の団体であり、その役員または理事のうちに反社会的勢力に該当する者があるとき。
                br
                | ⑺ 宿泊しようとする者が、伝染病に罹患していると明らかに認められるとき。
                br
                | ⑻ 宿泊に関し合理的な範囲を超える負担を求められたとき。
                br
                | ⑼ 天災、施設の故障、その他やむを得ない理由により宿泊させることができないとき。
                br
                | ⑽ 宿泊しようとする者が、他の宿泊者に著しく迷惑を及ぼすおそれがあると認められたとき。
                br
                | ⑾ 宿泊しようとする者が、他の宿泊者に著しく迷惑を及ぼす言動をしたとき。
                br
                | ⑿ 宿泊しようとする者が、当宿の役員または従業員等による業務遂行に対して支障を及ぼす言動をしたとき。
                br
                | ⒀ 宿泊しようとする者が、当宿の役員または従業員等の指示に正当な理由なく従わないとき。
                br
                | ⒁ 宿泊しようとする者が、危険物(ストーブ等の火器、石油類)、法令上所持もしくは使用が禁止される薬物または人体に有害な物品を持ちこむおそれがあると認められるとき。
                br
                | ⒂ 宿泊しようとする者が、過去に第6条第1項第1号、4号または5号の適用を受けた者であるとき。
                br
                | ⒃ その他、正当な理由のあるとき。
              p
                | (氏名等の申告)
                br
                | 第３条
                br
                | １ 当宿に宿泊契約の申込みをしようとする者は、次の事項を当宿に申し出ていただきます。
                br
                | ⑴ 宿泊者の住所、氏名および電話番号。
                br
                | ⑵ 宿泊日、到着予定時刻、申込者の電話番号および氏名。
                br
                | ⑶ その他当宿が必要と認めた事項。
                br
                | ２ 宿泊者が、宿泊中に前項第2号の宿泊日を超えて宿泊の継続を申し入れた場合、当宿は、その申し入れがなされた時点で、新たな宿泊契約の申込があったものとして処理いたします。
              p
                | (宿泊契約の成立等)
                br
                | 第４条
                br
                | １ 宿泊契約は、当宿が前条の申込みを承諾したときに成立するものとします。
                br
                | ２ 当宿は、宿泊契約が成立したときに、期限を定めて、宿泊期間(宿泊期間が3日間を超える場合は3日分)の宿泊料金を限度とする予約金の支払いを求めることがあり、この場合、当宿が定めた期限までに当該予約金をお支払いいただきます。
                br
                | ３ 前項の予約金は、第５条の定める場合に該当するときは、同条の違約金に充当し、残額があるときは、これを返還します。
              p
                | (宿泊者による宿泊契約の解除)
                br
                | 第５条
                br
                | １ 当宿は、宿泊契約の申込者が、宿泊契約の全部または一部を解除したときは、別表1に掲げるところにより違約金を申し受けます。
                br
                | ２ 当宿は、宿泊者が事前の連絡なく宿泊日の深夜0時（あらかじめ到着予定時刻が明示されていた場合は、当該時刻の2時間後）を経過しても到着しないときは、申込者により宿泊契約が解除されたものとみなし、処理することがあります。
                br
                | ３ 前項の場合において、宿泊者が事前の連絡なく宿泊日の深夜0時（あらかじめ到着予定時刻が明示されていた場合は、当該時刻の2時間後）を経過しても到着しなかったことが、列車、航空機等その他の公共の交通機関の遅延その他の宿泊者の責に帰さない理由によるものであることが証明されたときは、第１項の違約金は頂きません。
              p
                | (当宿による宿泊契約の解除)
                br
                | 第６条
                br
                | 当宿は、次の場合には宿泊契約を解除することができます。
                br
                | ⑴ 第2条第3号から第16号までに該当することとなったとき。
                br
                | ⑵ 第3条第1項各号規定の各事項を申し出ていただけないとき。
                br
                | ⑶ 第４条の予約金のお支払いを請求した場合において、期限までにそのお支払いがないとき。
                br
                | ⑷ 宿泊者以外の者を客室内に入れたとき。
                br
                | ⑸ ベッドでの寝たばこ、消防用設備等に対するいたずら、その他当宿が定める利用規則における禁止事項に従わないとき。
              p
                | (宿泊の登録)
                br
                | 第7条
                br
                | 宿泊者は、宿泊日当日、次の事項を当宿に登録してください。
                br
                | ⑴ 第3条第1項第1号の事項。
                br
                | ⑵ 外国人にあっては、国籍、旅券番号、入国地および入国年月日(旅券の写しを頂きます。ただし、日本国内に住所を有する場合はこの限りではありません。)。
                br
                | ⑶ 出発日。
                br
                | ⑷ その他、当宿が必要と認めた事項。
              p
                | (料金の支払い)
                br
                | 第８条
                br
                | 宿泊料金は、宿泊者がチェックアウトのときにお支払いください。
              p
                | (利用規則の遵守)
                br
                | 第9条
                br
                | 宿泊者は、当宿内においては、当宿の定める利用規則に従っていただきます。
              p
                | (寄託物等の取扱い)
                br
                | 第10条
                br
                | 当館では、物品又は現金並びに貴重品等をお預かりするサービスはとりおこなっておりません。滅失、毀損等の損害に関しましてはいかなる責任も負いかねます。
              p
                | (駐車の責任)
                br
                | 第11条
                br
                | 当宿は、当宿が管理していない駐車場（以下「提携駐車場」という。）内における車両、その付属装着物又は積載物の盗難、紛失又は毀損については一切責任を負いません。
                br
                | 当宿は、提携駐車場の利用者が、提携駐車場の他の利用者もしくはその他の人の行為又は提携駐車場内に存在する車両又はその付属装着物もしくは積載物等に起因して被った損害、その他提携駐車場内で発生した事象に起因して被った損害について一切責任を負いません。
              p
                | (当宿の責任)
                br
                | 第12条
                br
                | 当宿の宿泊契約に基づく責任は、宿泊者が当宿のフロントにおいて宿泊の登録を行ったとき、または客室に入ったときのうち、いずれか早い時期に始まり、宿泊者が出発するために客室を退出したときに終了します。
              p
                | (宿泊者の責任)
                br
                | 第13条
                br
                | 宿泊者の故意または過失により当宿が損害を被ったときは、当宿は、当該宿泊者に対し、その損害の賠償を請求できるものとします。
              p
                | (専属的合意管轄および準拠法)
                br
                | 第14条
                br
                | 本約款に関して生じる一切の紛争については、東京地方裁判所または東京簡易裁判所において、日本の法令に従い解決されるものとします。
              p
                | 別表１
                br
                | 違約金（第5条第1項関係）
              p
                | 契約解除の通知を受けた日
                table
                  tr
                    td
                      | 事前の連絡のない不泊	
                    td
                      | 当日
                    td
                      | 前日
                    td
                      | 2日～9日前
                  tr
                    td
                      | 100%
                    td
                      | 100%
                    td
                      | 30%
                    td
                      | 10%
                | 表中の%は、宿泊契約成立時に確定した宿泊料金に対する比率を示します。
                br
                | 違約金額は、宿泊契約が解除された客室に対応する宿泊料金総額に、解除された日時に応じて決定される上記表記載の比率を乗じた金額となります。


    .dialog-terms(v-if="showRules")
      .nav-wrap(v-on:click="showRules=false")
        a.menu
          img(src="@/assets/ico_close.svg")
      v-container
        v-layout(wrap)
          v-flex(xs12 sm8 lg6 xl6 offset-sm2 offset-lg3 offset-xl3 mb-4)
            .terms-name ご利用規則
            .terms-contents
              p
                | 当宿泊施設（以下、「当宿」といいます。）の公共性とお客様の安全かつ快適なご宿泊を確保するため、当宿をご利用のお客様には、宿泊約款第９条に基づき、下記の規則をお守りいただくことになっております。この規則をお守りいただけないときは、宿泊約款第6条により宿泊契約を解除いたします。
              p
                | (1) 客室を許可なく宿泊以外の目的に使用しないでください。
                br
                | (2) 客室を許可なく転貸しないでください。
                br
                | (3) 客室やロビーを事務所、営業所がわりに使用しないでください。
                br
                | (4) 館内で暖房用、炊事用の火器およびプレス用のアイロン等の火気を発するおそれのあるものを使用しないでください。ただし、当宿がお客様にご利用いただくために設置しているものはこの限りでありません。
                br
                | (5) 火災防止の為、客室その他火災の発生しやすい場所および当宿所定の場所以外で喫煙しないでください。
                br
                | (6) 館内および客室内で高声、放歌または喧騒な行為等で、他のお客様に嫌悪感を与えたり迷惑を及ぼしたりしないでください。
                br
                | (7) 館内に次のようなものを持ち込まないでください。
                span.indent
                  |  (イ) 動物、鳥類
                span.indent
                  |  (ロ) 悪臭を発するもの
                span.indent
                  |  (ハ) 著しく多量な物品
                span.indent
                  |  (二) 火薬や揮発油など、発火または引火しやすいもの
                span.indent
                  |  (ホ) 適法に所持を許可されていない銃砲、刀剣類または薬物等の物品
                span.indent
                  |  (へ) その他、他のお客様の安全性または快適性を損なう可能性があると当宿が認めたもの
                | (8) 館内で賭博等の公序良俗に反するような行為をしないでください。
                br
                | (9) 宿泊者以外の第三者を客室内に招かないでください。
                br
                | (10) 館内の諸設備、諸物品をその目的以外の用途に充てないでください。
                br
                | (11) 館内の諸物品を宿の外へ持ち出したり、館内の他の場所に移動しないでください。
                br
                | (12) 宿の建築物や館内の諸設備に物を取り付けたり、他の場所に移動しないでください。
                br
                | (13) 当宿の外観を損なうような品物を窓等に掲示しないてください。
                br
                | (14) 館内で他のお客様に対して広告物の配布、物品の販売、寄付や署名の募集等の行為を行わないでください。
                br
                | (15) 廊下やロビー等に所持品を放置しないでください。
                br
                | (16) 当宿が提供する飲食物を客室内に持ち込んだり、お客様が購入された飲食物であっても合理的範囲を超える量や内容の飲食物を客室に持ち込まないでください。
                br
                | (17) ご宿泊日数を変更される場合は、予めご連絡ください。
                br
                | (18) 泥酔者は当宿のお風呂のご利用をお断りいたします。
                br
                | (19) 貴重品や高価な物品は、客室内に放置せずに、必ず身につけてお出かけください。
                br
                | (20) 次に揚げる組織、個人については、当宿内諸施設のご利用をお断りいたします。
                span.indent
                  |  (イ) 「暴力団員による不当な行為の防止等に関する法律」に定める指定暴力団および指定暴力団連合またはその構成員、関係者、その他の反社会的勢力(以下、「反社会的勢力」といいます。)
                span.indent
                  |  (ロ) 反社会的勢力が事業活動を支配する法人その他の団体の関係者
                span.indent
                  |  (ハ) 暴行、傷害、脅迫、恐喝、威圧的不当要求またはこれに類する行為を行ったと認められる場合
                span.indent
                  |  (ニ) 心神耗弱、薬物等による自己喪失等によりご自身の安全確保が困難であったり、他のお客様に危険や恐怖感、不安感を及ぼすおそれがある者
                span.indent
                  |  (へ) 宿利用規則の違反について、当宿より注意を受けたにもかかわらず直ちにその行為を止めなかった者

</template>

<script>
  export default {
    data () {
      return {
        showPP: false,
        showTerms: false,
        showRules: false
      }
    }
  }
</script>