<template lang="pug">
  div
    // nav
    .nav-wrap
      a.menu(v-on:click="showMenu=!showMenu")
        img(src="@/assets/ico_close.svg" v-if="showMenu")
        img(src="@/assets/ico_nav.svg" v-else)
      a.ticket(v-scroll-to="'#contact'" @click="showMenu=false")
        img(src="@/assets/ico_ticket.svg")

    // nav open
    transition(name="menu")
      .menu-wrap.menu(v-if="showMenu")
        .menu-content
          a(href="/#about" @click="showMenu=false")
            .ico
              MenuAbout
            .menuname
              NameAbout
              span.cap ［ボルカニックアイランドとは］
          a(href="/#rental" @click="showMenu=false")
            .ico
              MenuRental
            .menuname
              NameRental
              span.cap ［レンタル］
          a(href="/#stay" @click="showMenu=false")
            .ico
              MenuStay
            .menuname
              NameStay
              span.cap ［宿泊］
          a(href="/#amenity" @click="showMenu=false")
            .ico
              MenuAmenity
            .menuname
              NameAmenity
              span.cap ［アメニティ］
          a(href="/#access" @click="showMenu=false")
            .ico
              MenuAccess
            .menuname
              NameAccess
              span.cap ［アクセス］
          a(href="/#fee" @click="showMenu=false")
            .ico
              MenuFee
            .menuname
              NameFee
              span.cap ［料金］
          a(href="/#contact" @click="showMenu=false")
            .ico
              MenuContact
            .menuname
              NameContact
              span.cap ［チケット&連絡先］

          .sns-wrap
            a(href="https://www.instagram.com/volcanic_island/" target="_blank")
              img(src="@/assets/ico_insta.svg")
            a(href="https://www.facebook.com/Volcanic-Island-696420117478262/" target="_blank")
              img(src="@/assets/ico_fb.svg")
            a(href="https://twitter.com/Volcanic_Is" target="_blank")
              img(src="@/assets/ico_tw.svg")

</template>

<script>
  import MenuAbout from '@/components/title/menuAbout'
  import NameAbout from '@/components/title/NameAbout'
  import MenuRental from '@/components/title/menuRental'
  import NameRental from '@/components/title/NameRental'
  import MenuStay from '@/components/title/menuStay'
  import NameStay from '@/components/title/NameStay'
  import MenuAmenity from '@/components/title/menuAmenity'
  import NameAmenity from '@/components/title/NameAmenity'
  import MenuAccess from '@/components/title/menuAccess'
  import NameAccess from '@/components/title/NameAccess'
  import MenuFee from '@/components/title/menuFee'
  import NameFee from '@/components/title/NameFee'
  import MenuContact from '@/components/title/menuContact'
  import NameContact from '@/components/title/NameContact'

  export default {
    components: {
      MenuAbout,
      NameAbout,
      MenuRental,
      NameRental,
      MenuStay,
      NameStay,
      MenuAmenity,
      NameAmenity,
      MenuAccess,
      NameAccess,
      MenuFee,
      NameFee,
      MenuContact,
      NameContact
    },
    data () {
      return {
        showMenu: false
      }
    },
    methods: {
      //
    }
  }
</script>
