<template lang="pug">
  #waytoohshima
    .contents-wrap
      .subttl-wrap
        h3
          | 伊豆大島までの行き方
      .texts-wrap.mb-narrow
        p
          strong.color-key [ 船で行く ]
          span ●竹芝桟橋（JR浜松町、大江戸線大門より徒歩10分）から高速船（東海汽船）で1時間45分
          span ●熱海港より高速船で45分
      .texts-wrap
        a.link-other(href="https://www.tokaikisen.co.jp/boarding/boarding_flow/" target="_blank")
          span 東海汽船株式会社公式サイト
          v-icon launch

      .texts-wrap.mb-narrow
        p
          strong.color-key [ 飛行機で行く ]
          span ●調布空港より新中央航空で35分

      .texts-wrap
        a.link-other2(href="https://www.central-air.co.jp/reservation/ibe/ibe/booking" target="_blank")
          span 新中央航空株式会社公式サイト
          v-icon launch

</template>

<script>
  export default {
    data () {
      return {
        //
      }
    }
  }
</script>

<style lang="scss">
  // color
  $bgcolor: #fff6dc;
  $keycolor: #EF5A00;
  $basecolor: #000000;
  $skycolor: #4BB5C7;

  .mb-narrow {
    margin-bottom: 12px;
  }
</style>
