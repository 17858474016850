<template lang="pug">
  #amenity
    .contents-wrap
      Title
        template(v-slot:img_src)
          IcoAmenity
        template(v-slot:ttl_name)
          NameAmenity
        template(v-slot:ttl_kana)
          | {{ ttl_kana }}

      .texts-wrap
        ul
          li シャンプー
          li リンス
          li ボディーソープ
          li ドライヤー
          li ハンガー
          li 洗濯機
          li お布団
          li プロジェクター
          li Wi-fi
</template>

<script>
  import Title from '@/components/parts/Title'
  import IcoAmenity from '@/components/title/IcoAmenity'
  import NameAmenity from '@/components/title/NameAmenity'

  export default {
    components: {
      Title,
      IcoAmenity,
      NameAmenity
    },
    data () {
      return {
        ttl_kana: '［アメニティ］'
      }
    }
  }
</script>

<style lang="scss">
  // color
  $bgcolor: #fff6dc;
  $keycolor: #EF5A00;
  $basecolor: #000000;
  $skycolor: #4BB5C7;
</style>
