<template lang="pug">
  div
    | A
    span.color-key C
    | CESS
</template>

<script>
  export default {
    components: {
      //
    },
    data () {
      return {
        //
      }
    }
  }
</script>

<style lang="scss">
</style>
