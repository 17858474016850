<template lang="pug">
  #fee
    .contents-wrap
      Title
        template(v-slot:img_src)
          IcoFee
        template(v-slot:ttl_name)
          NameFee
        template(v-slot:ttl_kana)
          | {{ ttl_kana }}

      .texts-wrap
        .table-wrap
          table
            tr
              th
                span.caption 一部屋
              td
              th
                span.caption お一人様あたり
            tr
              td
                span 1〜2名様
              td
                span ▶︎
              td
                span ¥5,500〜
            tr
              td
                span 3名様〜
              td
                span ▶︎
              td
                span ¥5,000〜
            tr
              td
                span 乳幼児
                span.caption （6歳未満）
              td
                span ▶︎
              td
                span.color-key 無料
        ul.md-text-center
          li お子様歓迎
          li 管理人常駐
          li 長期滞在割引あり
          li 貸切可
        p.md-text-center ※事前にご相談ください

      .texts-wrap
        .table-wrap
          table
            tr
              td
                span チェックイン
              td
                span 13:00〜20:00
            tr
              td
                span チェックアウト
              td
                span 10:00

        p.md-text-center
          span ご要望に応じチェックイン / アウトのお時間はご相談可能です！ 
          span 時間外での船の早朝到着、午後出発までの間はロビーでお過ごしいただくことができます。
          span 時間外のお荷物のお預かりなどもお気軽にご相談ください。

      Caution
        .cards-wrap
          img.cards(src="@/assets/cards.svg" alt="対応しているクレジットカード")
        p.md-text-center
          span
            | 現地でのお支払いは
            strong 上記クレジットカード、電子しまぽ、または交通系ICマネー（Suica / PASMO）、iD、QUICPay
            | となっております。ご注意ください。
          span ※その他の電子決済システム対応も準備中です！

      .button-wrap.mb-block
        span.cap-sky \ 東京の島をお得に旅できる電子マネー /
        a.link-sky(href="https://shimapo.com/" target="_blank")
          span 電子しまぽについて
          v-icon launch
</template>

<script>
  import Title from '@/components/parts/Title'
  import IcoFee from '@/components/title/IcoFee'
  import NameFee from '@/components/title/NameFee'
  import Caution from '@/components/parts/Caution'

  export default {
    components: {
      Title,
      IcoFee,
      NameFee,
      Caution
    },
    data () {
      return {
        ttl_kana: '［料金］'
      }
    }
  }
</script>

<style lang="scss">
  .cards-wrap {
    padding: 1.5vh 0;
    margin: auto;
    max-width: 500px;
  }
  .hr-dot {
    border: 0 !important;
    background-image: linear-gradient(to right, #000 20%, rgba(0,0,0,0) 0%);
    background-position: bottom;
    background-size: 10px 2px;
    background-repeat: repeat-x;
    height: 2px;
  }
  .namisen {
    border: 0 !important;
    background-image: url('~@/assets/namisen.svg');
    background-size: 300px;
    background-repeat: repeat-x;
    background-position: center;
    width: 100%;
    height: 30px;
  }
</style>
